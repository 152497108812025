<template>
  <div class="st-container">
    <SearchMultiSelect
      :selectedOptions="selected"
      :sourceOptions="destinationOptions"
      :hotDestinations="hotDestinations"
      :isCheckbox="3"
      :placeholder="$t('search-tab.choose-destination')"
      optionIcon="fa-map-marker-alt"
      tagIcon="fa-hotel"
      :theme="theme"
      @onChangeSelect="changedMultiSelection"
      @close="close"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SelectDestinations',
  components: {
    SearchMultiSelect: () => import('@/components/atoms/SearchMultiSelect'),
  },
  props: {
    type: {
      type: String,
      default: 'tabs',
    },
    data: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      // category: 'GET_CURRENT_CATEGORY',
      lang: 'GET_LANGUAGE',
      searchContent: 'GET_SEARCH_CONTENT',
      isLanding: 'GET_IS_LANDING_PAGE',
      isAgentSite: 'GET_ODYSSEY_AGENT_STATE',
    }),
    selected: {
      get() {
        return this.$store.state.searchContent.dest;
      },
      set(newValue) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['dest', newValue]);
      },
    },
    hotDestinations() {
      return this.data?.hotDestinations ?? [];
    },
  },
  watch: {
    lang() {
      this.makeSelectOption();
    },
  },
  data() {
    return {
      countSelect: 0,
      destinationOptions: [],
    };
  },
  methods: {
    makeSelectOption() {
      this.destinationOptions = [];
      if (this.data) {
        this.data.destinations.forEach((item, inx) => {
          this.destinationOptions.push({
            id: inx,
            value: item.code,
            label: item.nameTranslations[this.lang] ? item.nameTranslations[this.lang] : item.name,
          });
        });
      }
      this.getDestList();
    },
    getDestList() {
      const { city } = this.$route.query;
      if (this.isAgentSite && city) {
        const strDestination = decodeURIComponent(city) || '',
          destIdList = strDestination !== '' ? strDestination.split(',') : [];
        const dests = destIdList.map((id) => this.destinationOptions.find((item) => item.value === id));
        this.changedMultiSelection(dests, true);
        this.selected = dests;
      } else {
        this.selected = [];
      }
    },
    changedMultiSelection(pData, isAuto) {
      if (isAuto === undefined && pData && pData.length > 1) {
        this.$store.commit('SET_ALERT_MESSAGE', this.$t('search-result.impossible-multi-date-search'));
        this.$store.commit('SET_ALERT_MESSAGE_SHOW', true);
      } else {
        this.$store.commit('SET_ALERT_MESSAGE_SHOW', false);
      }
      this.selected = pData;
      this.$emit('changeDestination', pData);
    },
    close() {
      this.$emit('optionSelect', true);
    },
  },
  created() {
    this.makeSelectOption();
  },
};
</script>
<style scoped>
.st-container {
  overflow: visible;
}
</style>
<style>
input[type='search'] {
  margin: 0;
  padding: 0;
  border: 0;
}
.filter-tab .st-container .vs__dropdown-toggle {
  border-radius: 50px;
  padding: 0.575rem 1.35rem;
  height: 3rem;
}
.vs__selected {
  width: max-content;
}

.style-chooser .vs__dropdown-menu li {
  height: 45px;
  line-height: 35px;
  transition: 0.2s;
}

.style-chooser .vs__selected-options {
  display: contents;
}

.filter-tab form button.btn {
  height: 45px;
}
.checkbox-label {
  display: block;
}

.checkbox {
  position: absolute;
  left: 1vw;
}
.multiselect__tags {
  border-radius: 25px;
}
</style>
