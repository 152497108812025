import { render, staticRenderFns } from "./SelectDestinations.vue?vue&type=template&id=ff0efb36&scoped=true"
import script from "./SelectDestinations.vue?vue&type=script&lang=js"
export * from "./SelectDestinations.vue?vue&type=script&lang=js"
import style0 from "./SelectDestinations.vue?vue&type=style&index=0&id=ff0efb36&prod&scoped=true&lang=css"
import style1 from "./SelectDestinations.vue?vue&type=style&index=1&id=ff0efb36&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff0efb36",
  null
  
)

export default component.exports